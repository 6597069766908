import React from "react"
import style from "styled-components"

const SectionSolidBackground = style.div`
    width: 100%;
    // min-height: 100vh;
    min-height: ${props => props.minHeight};
    background-color: ${props => props.backgroundColor};
    z-index: 3;
    position: relative;
`

export default (props) => <SectionSolidBackground backgroundColor={props.backgroundColor} id={props.id} minHeight={props.minHeight}>{ props.children }</SectionSolidBackground>