import React from "react"
import styled from "styled-components"

const Text = styled.p`
    font-family: 'Archivo Narrow', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.15em;
    margin-bottom: ${props => props.marginBottom};
    margin-top: ${props => props.marginTop};

    a {
        // text-decoration: none;
        color: #000000;
    }
`

export default (props) => (
    <Text marginBottom={props.marginBottom} marginTop={props.marginTop}>{ props.children }</Text>
)