import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const CloseButton = styled.div`
    position: fixed;
    top: 30px;
    right: 30px;
    // pointer-events: all;
    // background: rgba(196, 196, 196, 0.5);
    width: 51px;
    height: 51px;
    border: none;
    // border-radius: 26px;
    cursor: pointer;
    :focus{
        outline: 0;
    }

    @media (max-width: 425px) {
        top: 15px;
        right: 15px;
    }
`

const Menu = styled.div`
    background: #fff;
    position: fixed;
    top: 92px;
    right: 30px;
    pointer-events: all;

    @media (max-width: 425px) {
        top: 77px;
        right: 15px;
    }
`

const MenuUnsortedList = styled.ul`
    padding: 20px 0;
    margin: 0;
`

const MenuListItem = styled.li`
    font-family: 'Archivo Narrow', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 54px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    list-style-type: none;
    cursor: pointer;
    :hover {
        background: rgba(196, 196, 196, 0.5);
    }
    a {
        text-decoration: none;
        color: #000;
        width: auto;
        height: 100%;
        display: block;
        padding: 0 20px;
    }
`

class HamburgerMenu extends React.Component {

    render() {
        return(
            <div>
                <CloseButton>
                    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="51" height="51" fill="none"/>
                        <rect x="17.1611" y="14" width="28" height="3" rx="1.5" transform="rotate(45 17.1611 14)" fill="white"/>
                        <rect x="36.9204" y="16.5815" width="28" height="3" rx="1.5" transform="rotate(135 36.9204 16.5815)" fill="white"/>
                    </svg>  
                </CloseButton>
                <Menu>
                    <nav>
                        <MenuUnsortedList>
                            <MenuListItem><a onClick={() => {this.props.onItemClick(); scrollTo('#our_restaurant')}}>Our restaurant</a></MenuListItem>
                            <MenuListItem><a onClick={() => {this.props.onItemClick(); scrollTo('#menu_')}}>Menu</a></MenuListItem>
                            <MenuListItem><a onClick={() => {this.props.onItemClick(); scrollTo('#info_')}}>Info</a></MenuListItem>
                            <MenuListItem><a onClick={() => {this.props.onItemClick(); scrollTo('#reservation')}}>Reservation</a></MenuListItem>
                        </MenuUnsortedList>
                    </nav>
                </Menu>
            </div>
        )
    }
}

export default HamburgerMenu