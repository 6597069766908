import React from "react"
import styled from "styled-components"

const Button = styled.button`
    position: fixed;
    top: 30px;
    right: 30px;
    pointer-events: all;
    background: rgba(196, 196, 196, 0.5);
    width: 51px;
    height: 51px;
    border: none;
    border-radius: 26px;
    cursor: pointer;
    :focus{
        outline: 0;
    }

    @media (max-width: 425px) {
        top: 15px;
        right: 15px;
    }
`

class HamburgerButton extends React.Component {

    render() {
        return(
            <Button type="button" onClick={this.props.onClick}>
                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="3" rx="1.5" fill="white"/>
                    <rect y="20" width="28" height="3" rx="1.5" fill="white"/>
                    <rect y="10" width="28" height="3" rx="1.5" fill="white"/>
                </svg>
            </Button>
        )
    }
}

export default HamburgerButton