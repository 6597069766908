import React from "react"
import style from "styled-components"

const SectionFlexCenter = style.div`
    display: flex;
    min-height: 100vh;
    flex-direction: ${props => props.direction};
    justify-content: center;
    align-items: center;
    z-index: 3;
    position: relative;
`

export default (props) => <SectionFlexCenter direction={ props.direction }>{ props.children }</SectionFlexCenter>