import React from "react"
import style from "styled-components"

const SectionFixedFullscreen = style.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
`

export default ({ children }) => <SectionFixedFullscreen>{ children }</SectionFixedFullscreen>