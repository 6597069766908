import React from "react"
import styled from "styled-components"

const Heading = styled.h2`
    border-bottom: solid 1px;
    font-family: 'Archivo Narrow', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: inline-block;
    padding-bottom: 0px;
    margin: ${props => props.margin};
`

export default (props) => (
    <Heading margin={ props.margin }>{ props.children }</Heading>
)