import React from "react"
import styled from "styled-components"

const Rectangle = styled.div`
    border: solid 2px;
    border-color: ${props => props.color};
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.color};
    line-height: 22px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
    width: fit-content;
    // padding: 15px;
    margin: 30px 0;
    :hover {
        background-color: ${ props => props.color};
        color: ${ props => props.hoverTextColor};
        // cursor: pointer;
    }
`

export default (props) => (
    <Rectangle color={ props.color } hoverTextColor={ props.hoverTextColor }>{ props.children }</Rectangle>
)