import React, { Component } from "react"
import styled from "styled-components"

import TresLogo from "../components/tres-logo"

const StyledMainLogo = styled.div`
    width: 80vw;
    max-width: ${props => props.maxWidth};
    // margin-bottom: 46px;
`

class MainLogo extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <StyledMainLogo maxWidth={this.props.maxWidth}>
                <TresLogo fill={this.props.color} />
            </StyledMainLogo>
        )
    }
}

export default MainLogo