import React, { Component } from "react"
import styled from "styled-components"

const BlackOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: all;
`



class Overlay extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <BlackOverlay onClick={this.props.onClick}>
                
            </BlackOverlay>
        )
    }
}

export default Overlay