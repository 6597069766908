import React from "react";

const SvgComponent = props => (
  <svg
    // x="0px"
    // y="0px"
    // width="284.5px"
    // height="86.75px"
    viewBox="0 0 284.5 86.75"
    // preserveAspectRatio="xMidYMin slice"
    // style="width: 100%; padding-bottom: 30%; height: 1px; overflow: visible"
    width="100%"
    // paddingBottom="30%"
    height="100%"
    // overflow="visible"
    {...props}
  >
    <path d="M128.767 7.725c-11.324-.784-22.763.151-34.019 1.392-20.402 2.249-40.635 6.041-60.708 10.309-3.662-2.844-7.771-5.116-12.287-6.621-3.934-1.311-8.351-1.708-12.26-.036-3.866 1.651-5.56 7.002-2.603 10.293 3.773 4.202 11.213 2.917 16.04 2.088 3.044-.523 6.06-1.25 9.079-1.903.475-.103.952-.202 1.427-.304a45.767 45.767 0 013.921 3.921c11.47 12.966 15.831 32.474 16.538 49.391.012.286.022.574.027.861.025 1.62 5.452 2.821 5.859.964 4.295-19.559 3.511-40.787 1.136-60.609 11.805-2.144 23.674-3.986 35.601-5.3 11.237-1.238 22.702-2.149 33.997-1.219 5.46.45 12.554 1.132 16.653 5.238 1.473 1.475 4.654.105 2.794-1.759-5.297-5.303-14.127-6.217-21.195-6.706zM20.818 22.2c-3.104.532-4.716 1.076-7.777.065-2.353-.776-1.955-4.005-.755-5.591 2.828-3.738 7.847-1.364 11.175-.037 2.357.939 4.555 2.187 6.613 3.638-3.079.669-6.152 1.392-9.256 1.925zM56.27 61.883c-1.77-9.743-5.013-19.32-9.712-27.714-2.512-4.485-5.651-8.567-9.339-12.028a893.86 893.86 0 0117.96-3.599c1.624 14.118 2.447 28.975 1.091 43.341z" />
    <path d="M211.622 56.227c-10.388 7.403-21.446 14.525-33.662 18.496-5.848 1.9-11.143 3.351-16.881.315-3.567-1.886-5.557-5.518-6.015-9.432a29.555 29.555 0 01-.097-.991 61.375 61.375 0 008.246-8.538c.504-.632.033-1.407-.512-1.78-2.416-1.656-5.892-2.362-8.602-1.06-3.62 1.742-4.835 6.038-5.086 9.769a16.98 16.98 0 00-.01 2.092c-9.621 7.095-21.651 11.392-33.327 10.182-7.951-.826-6.897-6.892-5.343-13.663.702-3.058-.074-6.231-2.537-7.86l-.521-.227c.284-1.404.308-2.905-.038-4.686-.781-4.02-6.476-3.349-6.7.671-.131 2.361.695 4.136 2.056 5.636C98.872 64.158 90.64 72.31 80.84 73.443c-2.489.288-2.161 3.285.331 2.998 4.982-.577 9.979-2.969 13.963-5.907 3.877-2.862 6.981-6.484 9.362-10.667a88.94 88.94 0 00.849-1.519c.024.389-.005.852-.171 1.383-.971 2.668-2.065 5.893-1.713 8.695.725 5.757 5.357 8.754 10.69 9.747 9.144 1.703 19.666-1.455 27.892-5.243a54.757 54.757 0 007.55-4.244c.807 2.876 2.408 5.495 4.829 7.404 5.759 4.543 13.645 4.094 20.41 2.633 14.269-3.083 27.406-11.802 39.092-20.131 2.136-1.521-.59-3.584-2.302-2.365zm-52.898-.214a54.83 54.83 0 01-3.671 3.948c.36-2.571 1.376-4.601 3.671-3.948z" />
    <path d="M213.673 78.619c2.944.836 8.058-.555 9.941-2.808 2.001-2.395 2.227-5.804 1.685-8.948 1.506-.163 3.028-.224 4.499-.305 3.137-.174 6.263-.711 9.338-1.327 6.991-1.399 13.818-3.649 20.361-6.464 5.464-2.351 10.896-5.325 14.99-9.713 1.382-1.481 2.421-3.313 2.917-5.278.643-2.555-2.795-1.989-3.302.02-1.287 5.104-8.121 8.251-12.363 10.36-6.604 3.279-13.759 5.533-20.934 7.174-4.713 1.078-10.891 1.038-16.406 2.118a18.277 18.277 0 00-1.14-2.583c-1.48-2.695-4.893-5.852-4.872-9.022.004-.662 1.31-.264 2.382.06 1.542.465 3.039 1.886 4.399 2.745 1.714 1.079 4.136-2.125 2.452-3.186-3.686-2.324-11.833-7.842-12.622.927-.409 4.529 2.119 8.565 3.741 12.797-3.238 1.495-5.812 3.887-7.04 7.896-.714 2.335-.726 4.77 1.974 5.537zm1.046-3.931c.02-.556.142-1.089.283-1.626.546-2.07 2.215-3.468 4.017-4.454.221-.12.446-.233.673-.339.193.87.319 1.752.337 2.655.058 3.034-2.353 5.157-5.303 3.895-.009-.003-.012 0-.019-.003.004-.04.01-.073.012-.128z" />
  </svg>
);

export default SvgComponent;
