import React from "react"
import styled from "styled-components"

import Heading from "../components/heading"
import Text from "../components/text"
import LSF from "../components/letter-spacing-fix"

const OurRestaurant = styled.div`
    max-width: 674px;
    padding: 100px 15% 100px 15%;

    @media (max-width: 425px) {
        padding: 100px 0;
        margin: 0 75px 0 20px;
    }
`

const Bold = styled.span`
    font-weight: 600;
`

// Indirectly needed to make it behave as expected on IE
const CenterHorizontallyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`

export default (props) => (
    <OurRestaurant id={ props.id }>
        <CenterHorizontallyWrapper><Heading>Our restauran<LSF>t</LSF></Heading></CenterHorizontallyWrapper>
        <Text>
            At Tres, we want to give you an intimate dining experience, a night without a single thought of yesterday or tomorrow. We will share the stories of our farmers and artisans that have contributed to who we are today and the restaurant’s philosophy.
        </Text>
        <Text>
            Tres is a 12 seat restaurant at Kop van Zuid-Entrepot in Rotterdam and we present a playful set menu. Starting with snacks, then onto savory dishes from the sea and the land, finishing with the sweet section of desserts and mignardises. We ask that you keep an open mind and that this night can take around three to four hours.
        </Text>
        <Text>
            The best producers and growers have been invited to be a part of this celebration of Dutch produce. We will show what can be grown, harvested, fished and foraged in this small but rich country.
        </Text>
        <Text>
            Complex without being complicated.
        </Text>
        <Text>
            - <Bold>Emy Koster</Bold> and <Bold>Michael van der Kroft</Bold>
        </Text>
    </OurRestaurant>
)