import React from "react"
import styled from "styled-components"

import Heading from "../components/heading"
import Text from "../components/text"
import LSF from "../components/letter-spacing-fix"

const RestaurantInformation = styled.div`
    max-width: 674px;
    // width: 20%;
    padding: 100px 15% 100px 15%;
    margin-right: 50%;
    text-align: left;

    @media (max-width: 768px) {
        // width: 50%;
        // padding: 100px 0;
        margin-right: 25%;
    }

    @media (max-width: 425px) {
        padding: 100px 0;
        margin: 0 75px 0 20px;
        width: auto;
    }
`

const Bold = styled.span`
    font-weight: 600;
`

const GreyText = styled.span`
    color: #939598;
`

const headingMargin = "100px 0 0 0"

export default (props) => (
    <RestaurantInformation id={ props.id }>
        <Heading>Hour<LSF>s</LSF></Heading>
        <Text>
            Thursday - Sunday<br />
            18.30 - 00.00
        </Text>
        <Text>
            Friday - Sunday lunch<br />
            12.00 - 16.00
        </Text>
        <Heading margin={ headingMargin } >Locatio<LSF>n</LSF></Heading>
        <Text>
            Entrepotgebouw<br />
            Vijf Werelddelen # 75<br />
            3071PS Rotterdam<br />
            The Netherlands
        </Text>
        <Text>Tres is also accessible by <Bold><a href="http://www.watertaxirotterdam.nl/" target="blank">Rotterdam’s Watertaxi</a></Bold>, arrival dock <Bold>Stieltjesstraat / Entrepot (38)</Bold>.</Text>
        <Heading margin={ headingMargin } >Contac<LSF>t</LSF></Heading>
        <Text>
            <a href="tel:0031618027316" style={{textDecoration: `none`}}>+31 (0) 6 18027316</a><br />
            <GreyText>Get in touch between 12 - 15</GreyText>
        </Text>
        <Text><a href="mailto:info@tresrotterdam.com" style={{textDecoration: `none`}}>info@tresrotterdam.com</a></Text>
        <Text>To apply for a position please email <a href="mailto:career@tresrotterdam.com" style={{textDecoration: `none`}}>career@tresrotterdam.com</a></Text>

    </RestaurantInformation>
)