import React from "react"
import styled from "styled-components"

const MenuItem = styled.p`
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-bottom: ${props => props.marginBottom}
`

export default (props) => (
    <MenuItem marginBottom={props.marginBottom}>{ props.children }</MenuItem>
)