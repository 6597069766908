import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null

const StyledPortal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
`

export default class Portal extends Component {

    constructor() {
        super()
        this.el = typeof document !== `undefined` ? document.createElement('div') : null
    }

    componentDidMount = () => {
        portalRoot.appendChild(this.el)
    }

    componentWillUnmount = () => {
        portalRoot.removeChild(this.el)
    }

    render() {
        const { children } = this.props

        if (this.el) {
            return ReactDOM.createPortal(<StyledPortal>{children}</StyledPortal>, this.el)
        } else {
            return null
        }
    }
}